export const environment = {
  production: true,
  encryptPassword: true,
  encryptRequest: false,
  encryptAdminApiRequest: false,
  clientUrl : 'https://clix-uat-campaign-userportal.ezee.ai/consumerAPI',
  adminUrl : 'https://clix-uat-campaign-userportal.ezee.ai/adminAPI',
  domain : 'https://clix-uat-campaign-userportal.ezee.ai',  // Domain on which the application will be deployed
  testUrl: 'https://clix-uat-campaign-userportal.ezee.ai/consumerAPI',
  notificationUrl: 'https://clix-uat-campaign-userportal.ezee.ai/',
  decisionEngineUrl: 'https://clix-uat-campaign-userportal.ezee.ai/decisionEngine',
  videoConferenceUrl: 'wss://rtcdr.idbibank.co.in/janus/',
  postVideoConferenceUrl: 'https://rtcdr.idbibank.co.in/api',
  videoRecordingPath: '/home/ubuntu/recording',
  googleApiKey: 'AIzaSyCPgKV2KK2YY_pzGHnk5DOnCYfMB74F5d8',
  loginUrl: 'https://clix-uat-auth.ezee.ai/auth/realms/clix-uat/protocol/openid-connect/auth?client_id=clix-uat&response_type=code&state=fj8o3n7bdy1op5&redirect_uri=https://clix-uat-campaign-userportal.ezee.ai',
  uamRedirectUrl: 'https://clix-uat-campaign-userportal.ezee.ai',
  customerUserPassword: "13232e0442360b3bca1033a3b3c5cc18:1291db1dc2832049f2330408a3a079fa",
  headingFontFamily:'Open Sans',
  contentFontFamily:'SourceSansPro-Regular',
  globalFontSizeContent:'16px',
  immediatePreview: false,
  vishwamSdkUrl: '',
  mid: '',
  repaymentApiKey: '',
  eid: '',
  repaymentUrl: '',
  finvuWebSocketUrl: "wss://webvwdev.finvu.in/consentapi",
  enableCompressedWidth: false,
  repaymentCheckoutUrl: '',
videoConferenceICEServers:'d8fa2aa208dac9637117e4693a812097:7f481edf88c874a25da606bfc9bb7d68c3d7d1b63c91917c061d29c8501c57b0cd1af23cb659d819efc144d98b67412833af45b83f50f52d748fccd4584869dd08b42a214e1311b2598e749517bc1a97872322f9ec0290968ae51796b294dc500724c38cd865a801faa2552091929f2d22893a7804bc882d160ddee029dcabb53632f39cab404fd2d4bcfc52f4546a26'
};
